import React from "react";
import { TextField } from '@mui/material';

import { Text } from "components";

const HomeColumn = ({label, value,  onChange}) => {
  const handleChnage = (ev)=> {
    onChange(ev.target.value)
  }
  return (
    <>
      <div>
        <div className="flex flex-col gap-2 items-start justify-start w-full">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex flex-col items-left justify-start w-full">
              <Text
                className="text-gray-900 text-lg w-auto"
                size="txtInterSemiBold18"
              >
                {label}
              </Text>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start py-3 w-full">
            <div className="flex flex-col items-left justify-start w-full">
              <TextField size="txtInterSemiBold18" value={value} type="email" onChange={(ev)=> {handleChnage(ev)}} className="text-gray-900 text-lg w-auto"
                id="standard-basic" variant="standard" label={'Enter ' + label}/>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeColumn;
