import React from "react";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { Img, Text } from "components";

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  console.log(props)
  return (
    <>
      <footer className={props.className}>

          <div className = {`bg-black-900 flex flex-col items-center justify-center  ${props.sustainable ? '' : 'mt-[550px]'} mx-auto w-full z-[1] homepage-pdl-footer`}>
            <div className="flex flex-col items-start justify-start max-w-[1440px] w-full">
              <div className="flex md:flex-col flex-row md:gap-10 gap-[152.1px] items-start justify-between pt-[120px] w-full">
                <div className="flex flex-1 flex-col h-full items-start justify-start w-full">
                  <div className="flex flex-col gap-6 items-start justify-start w-auto">
                    <Img
                      className="h-12 md:h-auto object-cover w-16 sm:w-full"
                      src="images/img_denlogoprode_48x64.svg"
                      alt="denlogoprode_One"
                    />
                    <Text
                      className="leading-[140.00%] md:max-w-full max-w-xs text-sm text-white-A700_a2"
                      size="txtRobotoRomanRegular14"
                    >
                      Powerful multi-cloud management platform, optimizing cloud
                      resources and costs for businesses of all sizes and
                      industries
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-1 md:flex-col flex-row md:gap-5 items-start justify-start w-auto md:w-full">
                  <div className="flex flex-col gap-6 h-52 md:h-auto items-start justify-start pb-[60px] pr-4 w-[200px]">
                    <div className="flex flex-col items-start justify-start w-auto">
                      <Text
                        className="text-sm text-white-A700 w-auto"
                        size="txtRobotoRomanMedium14"
                      >
                        Menu
                      </Text>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-[103px]">
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          <Link to="/whycs">Why CloudScore</Link>
                        </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          Services{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                        <Link to="/blog">Blog</Link>
                        </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          Contact
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 h-52 md:h-auto items-start justify-start pb-[60px] pr-4 w-[200px]">
                    <div className="flex flex-col items-start justify-start md:pr-10 sm:pr-5 pr-[143px] w-full">
                      <Text
                        className="text-sm text-white-A700 w-auto"
                        size="txtRobotoRomanMedium14"
                      >
                        Contact:
                      </Text>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start md:pr-10 sm:pr-5 pr-[74px] w-full">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          +1-469-441-3937
                        </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start md:pr-10 sm:pr-5 pr-[99px] w-full">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          contact@cloudscore.ai
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 items-start justify-start pb-[60px] pr-4 w-[200px]">
                    <div className="flex flex-col items-start justify-start md:pr-10 sm:pr-5 pr-[135px] w-full">
                      <Text
                        className="text-sm text-white-A700 w-auto"
                        size="txtRobotoRomanMedium14"
                      >
                        Address:
                      </Text>
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start md:pr-10 sm:pr-5 w-full">
                        <Text
                          className="leading-[140.00%] text-gray-200_01 text-sm"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          <>
                            United States
                            <br />
                            1300 W Walnut Hill Ln, STE195
                            Irving, TX 75038
                          </>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between pb-20 pt-10 w-full">
                <div className="flex sm:flex-col flex-row sm:gap-10 gap-[60px] h-6 md:h-auto items-center justify-start">
                  <div className="flex flex-col h-6 md:h-auto items-start justify-center">
                    <Text
                      className="text-gray-200_01 text-sm w-auto"
                      size="txtRobotoRomanRegular14Gray20001"
                    >
                      ©{currentYear} CLOUDSCORE
                    </Text>
                  </div>
                  <div className="flex flex-row gap-8 items-start justify-start w-auto">
                    <div className="flex flex-col items-start justify-start w-[119px]">
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          Terms of Services
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start w-auto">
                      <div className="flex flex-col items-start justify-start w-auto">
                        <Text
                          className="text-gray-200_01 text-sm w-auto"
                          size="txtRobotoRomanRegular14Gray20001"
                        >
                          Privacy Policy
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-4 items-center justify-start w-auto">
                <Tooltip title="Coming Soon">
                    <IconButton>
                      <InstagramIcon style={{ color: '#fff', fontSize: '28px' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Coming Soon">
                    <IconButton>
                      <XIcon style={{ color: '#fff', fontSize: '28px' }} />
                    </IconButton>
                  </Tooltip>
                  <a href="https://www.linkedin.com/company/cloud-score/?trk=public_post_feed-actor-name" target="_blank" rel="noopener noreferrer">
                  <Img
                    className="h-6 w-6"
                    src="images/img_link.svg"
                    alt="LinkedIn"
                  />
                  </a>
                  <Tooltip title="Coming Soon">
                    <IconButton>
                      <FacebookIcon style={{ color: '#fff', fontSize: '28px' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
};

Footer.defaultProps = {};

export default Footer;
