import React, { useContext, useState } from 'react';
import { SnackbarContext } from '../../utils/snackbarProvider';
import { postReq } from 'services/apiService';
import HomeColumn from "components/HomeColumn";
import HomeColumnlanguageThree from "components/HomeColumnlanguageThree";
import { Button } from "components";

const DemoComponent = () => {
    const openSnackbar = useContext(SnackbarContext);
    const handleToast = (message, type) => {
      openSnackbar(message, type);
    };
    const [btnDisable, setbtnDisable] = useState(false);
    const [homeColumnPropList, setHomeColumnPropList] = useState([
      { label: "First Name", value: '', payload: 'first_name' },
      { label: "Last Name", value: '',payload: 'last_name' },
      { label: "Email", value: '', payload: 'email'},
      { label: "Company", value: '', payload: 'company'},
      {label: "Message", value: '', payload: 'Message'}
    ]);

    const validateEmail = (email) => {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };
    const getDemo = async () => {
      const requiredFields = ['First Name', 'Last Name', 'Email', 'Company'];
      const missingFields = homeColumnPropList.filter(prop => requiredFields.includes(prop.label) && prop.value === '');
      if (missingFields.length > 0) {
        const message = `Please fill ${missingFields.map(field => `'${field.label}'`).join(', ')}`;
        handleToast(message, 'error');
        return
      }   
      const emailColumn = homeColumnPropList.find(el => el.label === 'Email');
      const validated = validateEmail(emailColumn.value);
      if(!validated){
        handleToast('Entered email is not valid', 'error');
        return
      }
      const payload = {};
      homeColumnPropList.forEach(prop => {
        payload[prop.payload] = prop.value;
      });
      setbtnDisable(true);
      const res = await postReq('api/v1/book_demo', payload);
      if(res?.data?.message){
        handleToast(res.data.message, 'success');
        const updatedList = homeColumnPropList.map(item => ({ ...item, value: '' }));
        setHomeColumnPropList(updatedList);
        setbtnDisable(false);
      }
    };
    
    const getMessageItem = () => {
      const messageItem = homeColumnPropList.find(item => item.label === 'Message');
      return messageItem;
    }
  
    const handleInputChange = (index, newValue) => {
      const field = homeColumnPropList[index];
      if (['First Name', 'Last Name', 'Company'].includes(field.label) && /\d/.test(newValue)) {
        handleToast('Numeric values are not allowed in this field', 'error');
        return;
      }

      const updatedList = [...homeColumnPropList];
      updatedList[index].value = newValue;
      setHomeColumnPropList(updatedList);
    };
  return (
        <div className="flex flex-1 flex-col gap-10 h-full items-start justify-start py-[60px] w-full homepage-pdl-footer">
        <div className="md:gap-5 gap-[40px] grid md:grid-cols-1 grid-cols-2 justify-center min-h-[auto] w-full">
                {homeColumnPropList.map((props, index) => (
                    props.label !== 'Message' &&
                    <div key={`HomeColumn${index}`}>
                    <HomeColumn key={props.label} {...props} onChange={(newValue) => handleInputChange(index, newValue)}/>
                    </div>
                ))}
                </div>
                <div className="md:gap-5 gap-[90px] grid md:grid-cols-1 grid-cols-1 justify-left min-h-[auto] w-full">
                <HomeColumnlanguageThree {...getMessageItem()} onChange={(newValue) => handleInputChange(4, newValue, true)}/>
                </div>
                <Button
                className="cursor-pointer font-semibold min-w-[147px] text-base text-center"
                shape="round"
                color="blue_A700"
                size="sm"
                variant="fill"
                onClick={getDemo}
                >
                Get the Demo
                </Button>
        </div>
  );
};

export default DemoComponent;


