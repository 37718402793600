import React, { createContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openSnackbar = (message, type) => {
    setAlign({...align, type1: type})
    setOpen(true);
    setMessage(message);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };
  const [align, setAlign] = useState({
    vertical: 'top',
    horizontal: 'right',
    type1: 'success'
  });
  const { vertical, horizontal, type1 } = align;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={handleClose}
          severity={type1}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};