import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "./utils/snackbarProvider";
import ProjectRoutes from "./Routes";

function App() {
  return (
    <SnackbarProvider>
      <Router>
        <ProjectRoutes />
      </Router>
    </SnackbarProvider>
  );
}

export default App;
