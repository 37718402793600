import axios from 'axios';

const api = axios.create({
    baseURL: 'https://website-admin.cloudscore.ai',
    headers: {'Content-Type': 'application/json'}
});

export const get = async (endpoint) => {
    try {
      const response = await api.get(endpoint);
      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
};

export const postReq = async (endpoint, data) => {
    try {      
      const response = await api.post(endpoint, data);
      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      throw new Error(`Error posting data: ${error.message}`);
    }
};